.form-box {
	input[type='text'],
	input[type='email'],
	input[type='tel'],
	input[type='password'],
	input[type='url'],
	input[type='number'],
	input[type='search'],
	.choices__inner,
	textarea,
	select {
		@apply inline-block w-full border border-solid border-black rounded-[5px] h-[60px] leading-[60px] text-[1.188rem] px-4 py-0 text-black focus:ring-0 focus:outline-0 focus:shadow-none focus:border-black;
	}

	textarea {
		@apply block h-[160px] leading-[1.563rem] py-4;
	}

	label {
		@apply block text-xl md:text-2xl !leading-none mb-2;
	}

	.form-checkbox {
		input:checked ~ span {
	        @apply before:block;
	    }
	}


	.choices__list--single {
		padding: 0 !important;
	}
	.choices{
		margin-bottom:0
	}
	.choices__list--dropdown, .choices__list[aria-expanded]{
		top:0;
	}
}

input::placeholder {
    @apply !text-gray-700;
}

input::input-placeholder,
textarea::input-placeholder {
    @apply !text-gray-700;
}

input:placeholder,
textarea:placeholder {
    @apply !text-gray-700;;
}

input::placeholder,
textarea::placeholder {
    @apply !text-gray-700;;
}

input:input-placeholder,
textarea:input-placeholder {
    @apply !text-gray-700;;
}

.iti--inline-dropdown .iti__dropdown-content {
	left:0 !important;
}